<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
        <v-col cols="12" sm="12" lg="12">
        <base-material-card
          :title="deliveryCardText"
          :text="regionInfo.name"
          :color="deliveryCardColor"
        >
        <br><br>
        <p>Current Delivery Status:</p>
          <v-form ref="form" lazy-validation @submit.prevent="submit">
            <v-select 
              v-model="newStatusText"
              :items="deliveryStatusOptions"
              label="Text description of region delivery status (optionally enter custom one manually)"
              dense
              solo
              persistent-hint
              @change="statusModified()"
              @submit="submit"
            />

            <v-btn 
              class="mr-11"
              type="submit"
              color="error"
              dense 
              :disabled="!modified"
            >
              submit
            </v-btn>
            <v-btn dense :disabled="!modified" @click="cancel">
              cancel
            </v-btn>
          </v-form>          
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
    <v-col>
              <base-material-card
          title="DXR Status"
          :text="regionInfo.dxr"
          :color="dxrColor"
        >
        <br>
            <v-row justify="center" align="center">
                <span class="font-weight-bold" >Orders</span>
            </v-row>
            

        </base-material-card>

    </v-col>


    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapState } from 'vuex';

export default {
  name: "DashboardDashboard",

  data() {
    return {
      newStatusText: null,
      modified: false
    }
  },  

  computed: {
    ...mapGetters(['regionInfo', 'regionId', 'deliveryStatusOptions']),
    ...mapState(['regionList']),
    deliveryCardText:  {
      get() {
        let s = this.regionInfo.region_id + ' '
        if (this.regionInfo.available) {
          s += ' - Currently accepting orders for delivery'
          this.deliveryCardColor = 'green'
        }
        else {
          s += ' - NOT currently accepting orders for delivery'
          this.deliveryCardColor = 'red'
        }
        if (this.regionInfo.dxr_online) {
          this.dxrColor = 'green'
        } else {
          this.dxrColor = 'red'
        }
        if (!this.modified) {
          this.newStatusText = this.regionInfo.description
        }
        return s
      }
    },
    regionInfo: {
      get() {
        return this.$store.state.regionInfo
      },
      set(value) {
        console.log("update regionInfo")
      }
    }
  },
  created() {
    this.startRegionUpdater()
  },

  methods: {
    ...mapGetters(['regionInfo']),
    startRegionUpdater() {
      setInterval(() => {
        this.$store.dispatch('regionDataUpdater')
      }, 10000)
    },


    submit (data) {
        this.modified = false
        this.regionInfo.description = this.newStatusText
        this.$store.dispatch('saveRegionInfo')
        console.log('here')
        console.log(this.regionInfo)
    },
    cancel () {
      this.$refs.observer.reset()
    },
    statusModified() {
      this.modified = true
    },

    complete(index) {
      this.list[index] = !this.list[index]
    }
  }
}
</script>
